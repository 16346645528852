import { ActivityStreamGuard, ComplianceGuard, DealMonitorGuard, DealQueryGuard } from '@core/guards/permission.guard';
import { RouterModule, Routes } from '@angular/router';

import { AcceptTermsComponent } from './modules/policies/components/accept-terms/accept-terms.component';
import { AcceptTermsGuard } from '@core/guards/accept-terms-guard';
import { AuthGuard } from '@core/guards/auth.guard';
import { DisclaimersComponent } from './modules/policies/components/disclaimers/disclaimers.component';
import { EquityDealMonitorGuard, EquityPublicDealsGuard, EquityDealQueryGuard } from '@core/guards/permission.guard';
import { MuniAssetsGuard } from '@core/guards/muni-assets.guard';
import { MuniDealMonitorGuard } from '@core/guards/muni-deal-monitor.guard';
import { MuniStreetCalendarGuard } from '@core/guards/muni-street-calendar.guard';
import { NgModule } from '@angular/core';
import { ProfileConfirmedGuard } from '@core/guards/profile-confirmation.guard';
import { RegulatoryDisclosuresComponent } from './modules/policies/components/regulatory-disclosures/regulatory-disclosures.component';
import { ResetPasswordGuard } from '@core/guards/reset-password.guard';
import { TermsAcceptedGuard } from '@core/guards/terms.guard';
import { NotEquityOnlyGuard } from '@core/guards/not-equity-only.guard';
import { CurrentAssetNotEquityGuard } from '@core/guards/current-asset-not-equity.guard';
import { HomePageGuard } from '@core/guards/home-page.guard';

/* eslint-disable max-len */
const routes: Routes = [
    {
        path: '',
        canActivate: [HomePageGuard],
        children: []
    },
    {
        path: 'login',
        loadChildren: () => import('./modules/login/login.module').then(m => m.LoginModule)
    },
    {
        path: 'reset-password',
        loadChildren: () => import('./modules/reset-password/reset-password.module').then(m => m.ResetPasswordModule),
        canActivate: [ResetPasswordGuard]
    },
    {
        path: 'muni-deals',
        loadChildren: () => import('./modules/lazy-loading-wrappers/muni-shared-lazy-load-wrapper.module').then(mod => mod.MuniSharedLazyLoadWrapperModule),
        canActivate: [AuthGuard, ProfileConfirmedGuard, TermsAcceptedGuard, MuniDealMonitorGuard]
    },
    {
        path: 'muni-deals/monitor',
        redirectTo: 'muni-deals/manager'
    },
    {
        path: 'muni-deals/manager',
        loadChildren: () => import ('./modules/lazy-loading-wrappers/muni-deal-monitor-lazy-load-wrapper.module').then(mod => mod.MuniDealMonitorLazyLoadWrapperModule),
        canActivate: [AuthGuard, ProfileConfirmedGuard, TermsAcceptedGuard, MuniDealMonitorGuard]
    },
    {
        path: 'muni-deals/calendar',
        loadChildren: () => import('./modules/lazy-loading-wrappers/muni-deal-calendar-lazy-load-wrapper.module').then(mod => mod.MuniDealCalendarLazyLoadWrapperModule),
        canActivate: [AuthGuard, ProfileConfirmedGuard, TermsAcceptedGuard, MuniStreetCalendarGuard]
    },
    {
        path: 'muni-deals/user-settings',
        loadChildren: () => import('./modules/user-settings/muni-user-settings.module').then(m => m.MuniUserSettingsModule),
        canActivate: [AuthGuard, TermsAcceptedGuard, ProfileConfirmedGuard, MuniAssetsGuard]
    },
    {
        path: 'muni-deals/support',
        loadChildren: () => import('./modules/support/muni.support.module').then(m => m.MuniSupportModule),
        canActivate: [AuthGuard, ProfileConfirmedGuard, TermsAcceptedGuard, MuniAssetsGuard]
    },
    {
        path: 'muni-deals/:id',
        loadChildren: () => import('./modules/lazy-loading-wrappers/muni-deal-show-lazy-load-wrapper.module').then(mod => mod.MuniDealShowLazyLoadWrapperModule),
        canActivate: [AuthGuard, ProfileConfirmedGuard, TermsAcceptedGuard, MuniStreetCalendarGuard]
    },
    {
        path: 'muni-deals/:id/comments-document/:documentId',
        loadChildren: () => import('./modules/lazy-loading-wrappers/document-viewer-lazy-load-wrapper.module').then(mod => mod.DocumentViewerLazyLoadWrapperModule)
    },
    {
        path: 'fixedincome',
        canActivate: [AuthGuard, TermsAcceptedGuard, ProfileConfirmedGuard],
        children: [
            {
                path: '',
                canActivate: [DealMonitorGuard],
                loadChildren: () => import('./modules/lazy-loading-wrappers/deal-monitor-lazy-load-wrapper.module').then(mod => mod.DealMonitorLazyLoadWrapperModule)
            },
            {
                path: 'deal/:dealId',
                loadChildren: () => import('./modules/lazy-loading-wrappers/deal-container-lazy-load-wrapper.module').then(mod => mod.DealContainerLazyLoadWrapperModule)
            },
            {
                path: 'deal/:dealId/:messageId',
                loadChildren: () => import('./modules/lazy-loading-wrappers/deal-container-lazy-load-wrapper.module').then(mod => mod.DealContainerLazyLoadWrapperModule)
            },
            {
                path: 'deal/:dealId/document/:documentId',
                loadChildren: () => import('./modules/lazy-loading-wrappers/document-viewer-lazy-load-wrapper.module').then(mod => mod.DocumentViewerLazyLoadWrapperModule)
            },
            {
                path: 'deal/:dealId/details/comments-document/:documentId',
                redirectTo: 'fixedincome/deal/:dealId/comments-document/:documentId'
            },
            {
                path: 'deal/:dealId/comments-document/:documentId',
                loadChildren: () => import('./modules/lazy-loading-wrappers/document-viewer-lazy-load-wrapper.module').then(mod => mod.DocumentViewerLazyLoadWrapperModule)
            },
            {
                path: 'analytics',
                loadChildren: () => import('./modules/lazy-loading-wrappers/analytics-lazy-load-wrapper.module').then(m => m.AnalyticsLazyLoadWrapperModule)
            },
            {
                path: 'user-settings',
                loadChildren: () => import('./modules/user-settings/fixed-income-user-settings.module').then(m => m.FixedIncomeUserSettingsModule),
                canActivate: [NotEquityOnlyGuard]
            },
            {
                path: 'support',
                loadChildren: () => import('./modules/support/buyside.support.module').then(m => m.BuysideSupportModule),
                canActivate: [CurrentAssetNotEquityGuard]
            }
        ]
    },
    {
        path: 'fixedincome/deal/:dealId/:messageId/details/comments-document/:documentId',
        redirectTo: 'fixedincome/deal/:dealId/:messageId/comments-document/:documentId'
    },
    {
        path: 'fixedincome/deal/:dealId/details/comments-document/:documentId',
        redirectTo: 'fixedincome/deal/:dealId/comments-document/:documentId'
    },
    {
        path: 'activity',
        canActivate: [AuthGuard, TermsAcceptedGuard, ProfileConfirmedGuard],
        children: [
            {
                path: '',
                canActivate: [ActivityStreamGuard],
                loadChildren: () => import('./modules/lazy-loading-wrappers/activity-stream-lazy-load-wrapper.module').then(mod => mod.ActivityStreamLazyLoadWrapperModule),
            },
            {
                path: 'deal/:dealId',
                loadChildren: () => import('./modules/lazy-loading-wrappers/deal-container-lazy-load-wrapper.module').then(mod => mod.DealContainerLazyLoadWrapperModule),
            },
            {
                path: 'deal/:dealId/:messageId',
                loadChildren: () => import('./modules/lazy-loading-wrappers/deal-container-lazy-load-wrapper.module').then(mod => mod.DealContainerLazyLoadWrapperModule),
            },
            {
                path: 'deal/:dealId/document/:documentId',
                loadChildren: () => import('./modules/lazy-loading-wrappers/document-viewer-lazy-load-wrapper.module').then(mod => mod.DocumentViewerLazyLoadWrapperModule),
            },
            {
                path: 'deal/:dealId/details/comments-document/:documentId',
                redirectTo: 'activity/deal/:dealId/comments-document/:documentId'
            },
            {
                path: 'deal/:dealId/comments-document/:documentId',
                loadChildren: './modules/lazy-loading-wrappers/document-viewer-lazy-load-wrapper.module#DocumentViewerLazyLoadWrapperModule'
            },
        ]
    },
    {
        path: 'activity/deal/:dealId/:messageId/details/comments-document/:documentId',
        redirectTo: 'activity/deal/:dealId/:messageId/comments-document/:documentId'
    },
    {
        path: 'activity/deal/:dealId/details/comments-document/:documentId',
        redirectTo: 'activity/deal/:dealId/comments-document/:documentId'
    },
    {
        path: 'compliance',
        canActivate: [AuthGuard, TermsAcceptedGuard, ProfileConfirmedGuard],
        children: [
            {
                path: '',
                loadChildren: () => import('./modules/lazy-loading-wrappers/compliance-lazy-load-wrapper.module').then(mod => mod.ComplianceLazyLoadWrapperModule),
                canActivate: [ComplianceGuard]
            },
            {
                path: 'deal/:dealId',
                loadChildren: () => import('./modules/lazy-loading-wrappers/deal-container-lazy-load-wrapper.module').then(mod => mod.DealContainerLazyLoadWrapperModule),
            },
            {
                path: 'deal/:dealId/:messageId',
                loadChildren: () => import('./modules/lazy-loading-wrappers/deal-container-lazy-load-wrapper.module').then(mod => mod.DealContainerLazyLoadWrapperModule),
            },
            {
                path: 'deal/:dealId/document/:documentId',
                loadChildren: () => import('./modules/lazy-loading-wrappers/document-viewer-lazy-load-wrapper.module').then(mod => mod.DocumentViewerLazyLoadWrapperModule),
            },
            {
                path: 'deal/:dealId/details/comments-document/:documentId',
                redirectTo: 'compliance/deal/:dealId/comments-document/:documentId'
            },
            {
                path: 'deal/:dealId/comments-document/:documentId',
                loadChildren: () => import('./modules/lazy-loading-wrappers/document-viewer-lazy-load-wrapper.module').then(mod => mod.DocumentViewerLazyLoadWrapperModule)
            },
        ]
    },
    {
        path: 'compliance/deal/:dealId/:messageId/details/comments-document/:documentId',
        redirectTo: 'compliance/deal/:dealId/:messageId/comments-document/:documentId'
    },
    {
        path: 'compliance/deal/:dealId/details/comments-document/:documentId',
        redirectTo: 'compliance/deal/:dealId/comments-document/:documentId'
    },
    {
        path: 'query',
        redirectTo: 'deal-query'
    },
    {
        path: 'query/deal/:dealId/:messageId/details/comments-document/:documentId',
        redirectTo: 'query/deal/:dealId/:messageId/comments-document/:documentId'
    },
    {
        path: 'query/deal/:dealId/details/comments-document/:documentId',
        redirectTo: 'query/deal/:dealId/comments-document/:documentId'
    },
    {
        path: 'deal-query',
        canActivate: [AuthGuard, TermsAcceptedGuard, ProfileConfirmedGuard],
        children: [
            {
                path: '',
                loadChildren: () => import('./modules/lazy-loading-wrappers/deal-query-lazy-load-wrapper.module').then(mod => mod.DealQueryLazyLoadWrapperModule),
                canActivate: [DealQueryGuard]
            },
            {
                path: 'deal/:dealId',
                loadChildren: () => import('./modules/lazy-loading-wrappers/deal-container-lazy-load-wrapper.module').then(mod => mod.DealContainerLazyLoadWrapperModule)
            },
            {
                path: 'deal/:dealId/:messageId',
                loadChildren: () => import('./modules/lazy-loading-wrappers/deal-container-lazy-load-wrapper.module').then(mod => mod.DealContainerLazyLoadWrapperModule)
            },
            {
                path: 'deal/:dealId/document/:documentId',
                loadChildren: () => import('./modules/lazy-loading-wrappers/document-viewer-lazy-load-wrapper.module').then(mod => mod.DocumentViewerLazyLoadWrapperModule)
            },
            {
                path: 'deal/:dealId/details/comments-document/:documentId',
                redirectTo: 'query/deal/:dealId/comments-document/:documentId'
            },
            {
                path: 'deal/:dealId/comments-document/:documentId',
                loadChildren: () => import('./modules/lazy-loading-wrappers/document-viewer-lazy-load-wrapper.module').then(mod => mod.DocumentViewerLazyLoadWrapperModule)
            },
        ]
    },
    {
        path: 'deal-query/deal/:dealId/:messageId/details/comments-document/:documentId',
        redirectTo: 'deal-query/deal/:dealId/:messageId/comments-document/:documentId'
    },
    {
        path: 'deal-query/deal/:dealId/details/comments-document/:documentId',
        redirectTo: 'deal-query/deal/:dealId/comments-document/:documentId'
    },
    {
        path: 'accept-terms',
        component: AcceptTermsComponent,
        canActivate: [AuthGuard, AcceptTermsGuard]
    },
    {
        path: 'disclaimers',
        component: DisclaimersComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'regulatory-disclosures',
        component: RegulatoryDisclosuresComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'user-profile',
        loadChildren: () => import('./modules/user-profile/user-profile.module').then(m => m.UserProfileModule),
        canActivate: [AuthGuard, TermsAcceptedGuard, ProfileConfirmedGuard]
    },
    {
        path: 'deal/:dealId/details/comments-document/:documentId',
        redirectTo: 'deal/:dealId/comments-document/:documentId'
    },
    {
        path: 'deal/:dealId/:messageId/details/comments-document/:documentId',
        redirectTo: 'deal/:dealId/:messageId/comments-document/:documentId'
    },
    {
        path: 'deal/:dealId/comments-document/:documentId',
        loadChildren: () => import('./modules/lazy-loading-wrappers/document-viewer-lazy-load-wrapper.module').then(mod => mod.DocumentViewerLazyLoadWrapperModule),
        canActivate: [AuthGuard, TermsAcceptedGuard, ProfileConfirmedGuard]
    },
    {
        path: 'deal/:dealId/:messageId/comments-document/:documentId',
        loadChildren: () => import('./modules/lazy-loading-wrappers/document-viewer-lazy-load-wrapper.module').then(mod => mod.DocumentViewerLazyLoadWrapperModule),
        canActivate: [AuthGuard, TermsAcceptedGuard, ProfileConfirmedGuard]
    },
    {
        path: 'deal/:dealId/document/:documentId',
        loadChildren: () => import('./modules/lazy-loading-wrappers/document-viewer-lazy-load-wrapper.module').then(mod => mod.DocumentViewerLazyLoadWrapperModule),
        canActivate: [AuthGuard, TermsAcceptedGuard, ProfileConfirmedGuard]
    },
    {
        path: 'deal/:dealId/:messageId/document/:documentId',
        loadChildren: () => import('./modules/lazy-loading-wrappers/document-viewer-lazy-load-wrapper.module').then(mod => mod.DocumentViewerLazyLoadWrapperModule),
        canActivate: [AuthGuard, TermsAcceptedGuard, ProfileConfirmedGuard]
    },
    {
        path: 'deal/:dealId',
        loadChildren: () => import('./modules/lazy-loading-wrappers/deal-container-lazy-load-wrapper.module').then(mod => mod.DealContainerLazyLoadWrapperModule),
        canActivate: [AuthGuard, TermsAcceptedGuard, ProfileConfirmedGuard]
    },
    {
        path: 'notification/deal/:dealId',
        redirectTo: 'deal/:dealId'
    },
    {
        path: 'deal/:dealId/:messageId',
        loadChildren: () => import('./modules/lazy-loading-wrappers/deal-container-lazy-load-wrapper.module').then(mod => mod.DealContainerLazyLoadWrapperModule),
        canActivate: [AuthGuard, TermsAcceptedGuard, ProfileConfirmedGuard]
    },
    {
        path: 'notification/deal/:dealId/:messageId',
        redirectTo: 'deal/:dealId/:messageId'
    },
    {
        path: 'equity',
        canActivate: [AuthGuard, TermsAcceptedGuard, ProfileConfirmedGuard],
        children: [
            {
                path: 'deal-monitor',
                canActivate: [EquityDealMonitorGuard],
                children: [
                    {
                        path: '',
                        loadChildren: () => import('./modules/lazy-loading-wrappers/eq-deal-monitor-lazy-load-wrapper.module').then(mod => mod.EquityDealMonitorLazyLoadWrapperModule)
                    },
                    {
                        path: 'deal/:dealId',
                        loadChildren: () => import('./modules/lazy-loading-wrappers/eq-deal-container-lazy-load-wrapper.module').then(mod => mod.EquityDealContainerLazyLoadWrapperModule)
                    },
                ]
            },
            {
                path: 'deal-query',
                canActivate: [EquityDealQueryGuard],
                children: [
                    {
                        path: '',
                        loadChildren: () => import('./modules/lazy-loading-wrappers/eq-deal-query-lazy-load-wrapper.module').then(mod => mod.EquityDealQueryLazyLoadWrapperModule)
                    },
                    {
                        path: 'deal/:dealId',
                        loadChildren: () => import('./modules/lazy-loading-wrappers/eq-deal-container-lazy-load-wrapper.module').then(mod => mod.EquityDealContainerLazyLoadWrapperModule)
                    },
                ]
            },
            {
                path: 'public-deals',
                canActivate: [EquityPublicDealsGuard],
                loadChildren: () => import('./modules/lazy-loading-wrappers/eq-public-deals-lazy-load-wrapper.module').then(mod => mod.EquityPublicDealsLazyLoadWrapperModule)
            },
            {
                path: 'analytics',
                loadChildren: () => import('./modules/lazy-loading-wrappers/eq-analytics-lazy-load-wrapper.module').then(m => m.EquityAnalyticsLazyLoadWrapperModule)
            },
            {
                path: 'support',
                canActivate: [EquityDealMonitorGuard],
                loadChildren: () => import('./modules/support/equity.support.module').then(m => m.EquitySupportModule)
            },
            {
                path: 'user-settings',
                canActivate: [EquityDealMonitorGuard],
                loadChildren: () => import('./modules/user-settings/equity-user-settings.module').then(m => m.EquityUserSettingsModule)
            },
            {
                path: 'support',
                canActivate: [EquityDealMonitorGuard],
                loadChildren: () => import('./modules/support/equity.support.module').then(m => m.EquitySupportModule)
            },
            {
                path: '',
                redirectTo: 'deal-monitor',
                pathMatch: 'prefix'
            }
        ]
    },
    {
        path: 'options',
        children: [
            {
                path: 'userSettings',
                redirectTo: '/fixedincome/user-settings'
            },
            {
                path: 'userProfile',
                redirectTo: '/user-profile'
            },
            {
                path: 'support',
                redirectTo: '/fixedincome/support'
            }
        ]
    },
    {
        path: 'reset_password',
        redirectTo: 'reset-password'
    },
    {
        path: 'acceptTerms',
        redirectTo: 'accept-terms'
    },
    {
        path: 'privacyPolicy',
        redirectTo: 'privacy-policy'
    },
    {
        path: 'thirdPartyData',
        redirectTo: 'third-party-data'
    },
    {
        path: 'regulatoryDisclosures',
        redirectTo: 'regulatory-disclosures'
    },
    {
        path: 'equity-public-deals',
        redirectTo: 'equity/public-deals'
    },
    {
        path: 'admin',
        loadChildren: () => import('./modules/admin-client/admin-client.module').then(m => m.AdminClientModule),
        canActivate: [ AuthGuard, TermsAcceptedGuard, ProfileConfirmedGuard ]
    },
    {
        path: '**',
        redirectTo: '/'
    }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
    useHash: true,
    enableTracing: false,
    anchorScrolling: 'enabled',
    relativeLinkResolution: 'legacy'
})
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
