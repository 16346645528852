import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { RouterEvent, NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';

import { Observable, Subscription, BehaviorSubject } from 'rxjs';
import { select, Store } from '@ngrx/store';
import * as fromRoot from './reducers';
import * as fromLayout from '@core/@state/reducers/layout.reducer';
import { PlatformWebSocketsConnector } from '@core/live-update/websockets/platform-websockets-connector';
import { JwtLifetimeProcessor } from '@core/authentication/jwt/jwt.lifetime.processor';
import { appSessionTimeoutStorageKey, JwtTokenType, applicationTitle } from '@core/constants/constants';
import { IdleService } from '@core/services/idle.service';
import { debounceTime, withLatestFrom, filter } from 'rxjs/operators';
import { StorageService } from '@core/services/storage.service';
import { AuthApiActions, LoggerActions } from '@core/@state/actions';
import { Title } from '@angular/platform-browser';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { BspConfiguration } from '@core/configuration/bsp-configuration';
import { EndNavigation, StartNavigation } from '@core/@state/actions/layout.actions';
import { AssetClassService } from '@core/services/asset-class.service';
import { environment } from '@environments/environment';
import * as listeners from '@core/live-update/websockets/listeners';
import { WINDOW } from '@core/services/window.service';
import { composeElementSelector } from '@core/utils/utils';
import { ClientLogLevel } from '@core/logger/log.model';
import { NotificationService } from '@core/notifications/notification.service';
import { WebsocketMonitorService } from '@core/websockets/websocket.monitor';

@AutoUnsubscribe()
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
    public websocketToken$ = new BehaviorSubject<string>(null);
    public pomToken$ = new BehaviorSubject<string>(null);
    public showIdlePopup: boolean;
    public isAuthPassed: boolean;
    public sessionNotifyTimeout: number;
    public isNavigating$: Observable<boolean>;
    public showDisconnectAlert$: Observable<boolean>;

    private navigationStartSubscription: Subscription;
    private navigationEndSubscription: Subscription;
    private routerStateSubscription: Subscription;
    private idleSubscription: Subscription;
    private isLoggedInSubscription: Subscription;
    private isConfirmedSubscription: Subscription;

    constructor(
        private webSocketsGatewayConnector: PlatformWebSocketsConnector,
        private jwtLifetimeService: JwtLifetimeProcessor,
        private store: Store<fromRoot.State>,
        private idle: IdleService,
        private storage: StorageService,
        private titleService: Title,
        private router: Router,
        private assetClassService: AssetClassService,
        private websocketLiveUpdateListener: listeners.WebsocketLiveUpdateListener,
        private websocketDesktopNotificationListener: listeners.WebsocketDesktopNotificationListener,
        private websocketTestMessageListener: listeners.WebsocketTestMessageListener,
        private websocketEquityLiveUpdateListener: listeners.WebsocketEquityLiveUpdateListener,
        private notificationService: NotificationService,
        private websocketMonitorService: WebsocketMonitorService,
        @Inject(WINDOW) private window: Window
    ) {}

    public ngOnInit(): void {
        this.isLoggedInSubscription = this.store.pipe(select(fromRoot.getIsLoggedIn))
            .subscribe(isLoggedIn => {
                if (!isLoggedIn) {
                    return;
                }

                this.logBrowserNotificationStatus();
            });

        this.isConfirmedSubscription = this.store.pipe(
            select(fromRoot.getIsUserProfileConfirmed),
            withLatestFrom(this.store.pipe(select(fromRoot.getConfigurationState)))
        ).subscribe(([isConfirmed]) => this.handleWebSocketConnections(isConfirmed));

        this.navigationStartSubscription = this.router.events.pipe(
            filter(event => event instanceof NavigationStart)
        ).subscribe(() => {
            this.store.dispatch(new StartNavigation());
        });

        this.navigationEndSubscription = this.router.events.pipe(
            filter(event => {
                return (event instanceof NavigationEnd)
                    || (event instanceof NavigationCancel)
                    || (event instanceof NavigationError);
            })
        ).subscribe((event: RouterEvent) => {
            this.store.dispatch(new EndNavigation({
                path: event.url
            }));
        });

        this.routerStateSubscription = this.store.pipe(
            select(fromRoot.getRouterState),
            withLatestFrom(
                this.store.pipe(select(fromRoot.getIsLoggedIn)),
                this.store.pipe(select(fromRoot.getIsUserProfileConfirmed))
            )
        )
        .subscribe(([data, isLoggedIn, isConfirmed]) => {
            if (data && data.state && data.state.url) {
                this.isAuthPassed = (isLoggedIn && isConfirmed);
            } else {
                this.isAuthPassed = false;
            }
        });

        this.idleSubscription = this.store.pipe(
            select(fromRoot.getIsLoggedIn),
            withLatestFrom(this.store.pipe(select(fromRoot.getConfigurationState)))
        )
            .subscribe(([isLoggedIn, configuration]) => this.handleIdle(isLoggedIn, configuration));

        this.assetClassService.subscribeOnAssetChange();

        this.isNavigating$ = this.store.pipe(
            select(fromLayout.getIsNavigating),
            debounceTime(100)
        );

        this.showDisconnectAlert$ = this.store.pipe(select(fromLayout.getShowDisconnectAlert));

        this.window.addEventListener('click', (event: MouseEvent) => this.onClientAction(event.target));
        this.window.addEventListener('keypress', (event: KeyboardEvent) => this.onClientAction(event.target));
    }

    private handleWebSocketConnections(isConfirmed: boolean): void {
        // disable WebSocket company-connections for e2e tests
        if (!environment.e2e) {
            this.websocketLiveUpdateListener.initialize();
            this.websocketDesktopNotificationListener.initialize();
            this.websocketTestMessageListener.initialize();
            this.websocketEquityLiveUpdateListener.initialize();
        }

        if (isConfirmed) {
            /*
                After user is logged in:
                - initiate connection with Northstar Alerts API (new live updates flow);
                - subscribe on token updates and pass down the token to Northstar-Alerts component.
             */
            this.jwtLifetimeService.subscribe(JwtTokenType.WebsocketGateway, (newToken) => {
                this.websocketToken$.next(newToken);
                this.webSocketsGatewayConnector.connect(newToken);
            });

            this.jwtLifetimeService.subscribe(JwtTokenType.Pom, (newToken) => {
                this.pomToken$.next(newToken);
            });

            this.websocketMonitorService.init();

            return;
        }

        /*
            After user is logged out:
            - terminate webSocketsGateway connection;
            - stop jwtLifetimeService for both POM and WebsocketGateway tokens
         */
        this.webSocketsGatewayConnector.disconnect();

        this.jwtLifetimeService.unsubscribe(JwtTokenType.WebsocketGateway);
        this.jwtLifetimeService.unsubscribe(JwtTokenType.Pom);
    }

    private handleIdle(isLoggedIn: boolean, configuration: BspConfiguration): void {
        if (!isLoggedIn) {
            this.idle.stop();

            return;
        }

        this.sessionNotifyTimeout = configuration.sessionNotifyTimeout;

        this.idle.stop();
        this.idle.start(configuration.sessionNotifyTimeout * 60 * 1000, configuration.sessionLogoutTimeout * 60 * 1000);

        this.idle.onIdle.subscribe((event) => {
            this.titleService.setTitle(`${event.secondsLeft} seconds till session timeout`);
            this.showIdlePopup = true;
        });

        this.idle.onIdleTerminated.subscribe(() => {
            this.titleService.setTitle(applicationTitle);
            this.showIdlePopup = false;
        });

        this.idle.onTimeout.subscribe(() => {
            this.showIdlePopup = false;
            this.storage.localStorage.setObject(appSessionTimeoutStorageKey, { value: true, type: 'boolean' });
            this.store.dispatch(new AuthApiActions.Logout());
        });
    }

    private onClientAction(target: EventTarget): void {
        const el = target as HTMLElement;

        const elementSelector: string = composeElementSelector(el);

        this.store.dispatch(new LoggerActions.AddElementClicked({ elementClicked: elementSelector }));
    }

    private logBrowserNotificationStatus(): void {
        this.notificationService.checkPermissions().then(isPermissioned => {
            this.store.dispatch(new LoggerActions.LogMessages(
                {
                    logs: [{
                        entryDate: new Date(),
                        message: `Client\'s browser notifications are enabled: ${isPermissioned}`,
                        level: ClientLogLevel.Info,
                        extraInfo: null
                    }],
                    addExtraInfo: true
                })
            );
        });
    }

    public ngOnDestroy(): void {}
}
