<div class="c-card c-card-secondary">
  <header class="c-card-header">
    <div class="c-card-header-title">
      Settings
    </div>
    <div class="c-card-header-menu">
      <div class="c-card-header-menu-item">
        <a (click)="hide()"><span class="fa fa-close"></span></a>
      </div>
    </div>
  </header>
  <div class="c-card-content">
    <div *ngIf="notificationService.supported" class="desktop c-p-md c-p-bottom-lg">
      <div class="labels">
        <div class="c-text-bold">Desktop Notifications</div>
        <div class="c-text-sm">
          Receive desktop alerts for new notifications
        </div>
      </div>
      <div class="switch">
        <spr-switch [checked]="notificationsEnabled"
                      [legacy]="false"
                      [size]="'md'"
                      (change)="notificationChange($event)">
        </spr-switch>
      </div>
    </div>
    <div class="alert-types">
      <div
        class="header c-text-bold c-text-sm c-text-gray-6 c-bd-bottom c-bd-top c-p-md c-p-top-sm c-p-bottom-sm c-bg-gray-2">
        NOTIFICATION TYPES
      </div>
      <div *ngFor="let messageTypePreference of (messageTypePreferences$ | async)"
        class="message-type c-p-md c-bd-bottom">
        <div class="labels">
          <div class="c-text-bold">
            {{ messageTypePreference.messageType.typeName }}
          </div>
          <div class="c-text-sm">
            {{ messageTypePreference.messageType.description }}
          </div>
        </div>
        <div class="switch">
          <spr-switch [checked]="messageTypePreference.preference && messageTypePreference.preference.active"
                      [legacy]="false"
                      [size]="'md'"
                      (change)="preferenceChange(messageTypePreference, $event)">
          </spr-switch>
        </div>
      </div>
    </div>
  </div>
</div>