import { Injectable } from '@angular/core';

import {
    UserContextModel,
    UserContextProfileCompany,
    UserContextProfileContainer,
    UserContextProfileModel,
    UserContextUserSettingModel
} from '../usercontext/user-context-http.model';
import {
    NumberFormat,
    OrderHedgeType,
    UserProfile,
    UserSettings,
    Company,
    Container,
    OrderEntryFormat
} from './user-settings.model';
import { UserSettingsOptions } from './user-settings.options';
import { CommonUserSettings, DefaultOperationalEntities, DefaultOperationalEntity, UserSettingsHttpModel } from '../../modules/user-settings/models/user-settings-http.model';

@Injectable({
    providedIn: 'root'
})
export class UserSettingsService {

    private readonly DEFAULT_USER_SETTINGS: Partial<CommonUserSettings> = {
        language: 'en-US',
        currency: 'USD',
        dateFormat: 'Format4',
        timeFormat: 'Format0',
        timezone: 'America/New_York',
        numberFormat: 'Format0'
    };

    private readonly SETTINGS_STORE_NAME: Partial<{[key in keyof UserSettingsHttpModel]}> = {
        app_companyOrderSettings: 'companySortOrder',
        app_dealMonitor1RequestSettings: {
            pricedDealsWindowDays: 'pricedDealsWindowDays',
            showChineseDeals: 'showChineseDeals'
        },
        app_orderEntryFormat: 'orderEntryFormat',
        app_maxLevelAmount: 'maxLevelAmount',
        app_hedgeType: 'orderHedgeType',
        app_hedgeSettlement: 'orderHedgeSettlement',
        app_userSettings: {
            language: 'language',
            dateFormat: 'dateFormat',
            timeFormat: 'timeFormat',
            timezone: 'timeZone',
            numberFormat: 'numberFormat',
            homePage: 'homePage'
        },
        app_defaultOperationalEntities: 'defaultOperationalEntities',
        app_singleInAppEmailNotifications: 'singleInAppEmailNotifications'
    };

    private readonly PRICED_DEALS_WINDOW_DAYS_DEFAULT = 7;

    public buildSettings(context: UserContextModel): UserSettings {
        const profile = context.Profile;

        return {
            language: this.getLanguage(profile.Language),
            currency: this.getCurrency(profile.Currency),
            dateFormat: this.getDateFormat(profile.DateFormat),
            timeFormat: this.getTimeFormat(profile.TimeFormat),
            timeZone: this.getTimeZone(profile.Timezone),
            numberFormat: this.getNumberFormat(profile.NumberFormat),
            profile: this.getProfile(profile),
            companies: this.getCompanies(profile.Companies),
            containers: this.getContainers(profile.Containers),
            companySortOrder: this.getCompanySortOrder(context.UserSettings),
            isAccessingFromUS: context.IsAccessingFromUS,
            hideEmptyFields: this.getHideEmptyFields(context.UserSettings),
            highlightChanges: this.getShowChanges(context.UserSettings),
            pricedDealsWindowDays: this.getPricedDealsWindowDays(context.UserSettings),
            showChineseDeals: this.getShowChineseDeals(context.UserSettings),
            orderEntryFormat: this.getOrderEntryFormat(context.UserSettings),
            maxLevelAmount: this.getMaxLevelAmount(context.UserSettings),
            orderHedgeType: this.getOrderHedgeType(context.UserSettings),
            defaultOperationalEntities: this.getDefaultOperationalEntities(context.UserSettings),
            singleInAppEmailNotifications: this.getSingleInAppEmailNotifications(context.UserSettings),
            orderHedgeSettlement: this.getOrderHedgeSettlement(context.UserSettings)
        };
    }

    public getDealMonitorSettings(settingKey: string, userSettings: UserContextUserSettingModel[]): string | null {
        const setting = userSettings.find(s => s.SettingKey === settingKey);

        return !setting ? null : setting.SettingValue;
    }

    public getDealQueryV2Settings(userSettings: UserContextUserSettingModel[]): any | null {
        const dealQueryV2Settings = userSettings.find(s => s.SettingKey === 'app_dealQueryV2Settings');
        if (!dealQueryV2Settings) {
            return null;
        }

        return dealQueryV2Settings.SettingValue;
    }

    public getSettingStoreName(setting: string, fieldName: string): string {
        switch (setting) {
            case 'app_userSettings':
                return this.SETTINGS_STORE_NAME.app_userSettings[fieldName];
            case 'app_dealMonitor1RequestSettings':
                return this.SETTINGS_STORE_NAME.app_dealMonitor1RequestSettings[fieldName];
            default:
                return this.SETTINGS_STORE_NAME[setting];
        }
    }

    public getProfile(profile: UserContextProfileModel<UserContextProfileContainer, UserContextProfileCompany>): UserProfile {
        return {
            city: profile.City,
            country: profile.Country,
            createdBy: profile.CreatedBy,
            creationDate: profile.CreationDate,
            email: profile.Email,
            emails: profile.Emails,
            firstName: profile.FirstName,
            id: profile.Id,
            lastName: profile.LastName,
            phoneNumber: profile.PhoneNumber,
            phones: profile.Phones,
            title: profile.Title,
            userName: profile.UserName,
            acceptTermsOfUseRequired: profile.AcceptTermsOfUseRequired,
            passwordChangeRequired: profile.PasswordChangeRequired,
            homePage: profile.HomePage
        };
    }

    public mapUserSettingValue(key: string, value: string): any {
        switch (key) {
            case 'dateFormat': {
                return this.getDateFormat(value);
            }
            case 'numberFormat': {
                return this.getNumberFormat(value);
            }
            case 'timeFormat': {
                return this.getTimeFormat(value);
            }
            case 'timezone': {
                return this.getTimeZone(value);
            }
            default: {
                return value;
            }
        }
    }

    private getLanguage(profileLanguage: string): string {
        return profileLanguage ?
            UserSettingsOptions.USER_SETTINGS_OPTIONS.languages[profileLanguage] :
            this.DEFAULT_USER_SETTINGS.language;
    }

    private getCurrency(profileCurrency: string): string {
        return profileCurrency ?
            profileCurrency :
            this.DEFAULT_USER_SETTINGS.currency;
    }

    private getDateFormat(profileDateFormat: string): string {
        const dateFormat = profileDateFormat || this.DEFAULT_USER_SETTINGS.dateFormat;

        return UserSettingsOptions.USER_SETTINGS_OPTIONS.dateFormats[dateFormat].momentFormat;
    }

    private getTimeFormat(profileTimeFormat: string): string {
        const timeFormat = profileTimeFormat || this.DEFAULT_USER_SETTINGS.timeFormat;

        return UserSettingsOptions.USER_SETTINGS_OPTIONS.timeFormats[timeFormat].momentFormat;
    }

    private getTimeZone(profileTimeZone: string): string {
        return profileTimeZone || this.DEFAULT_USER_SETTINGS.timezone;
    }

    private getNumberFormat(profileNumberFormat: string): NumberFormat {
        const numberFormat = profileNumberFormat || this.DEFAULT_USER_SETTINGS.numberFormat;

        return UserSettingsOptions.USER_SETTINGS_OPTIONS.numberFormats[numberFormat];
    }

    private getCompanySortOrder(userSettings: UserContextUserSettingModel[]): number[] {
        const companySortOrder = this.getUserSettingValue(userSettings, 'app_companyOrderSettings');

        return companySortOrder || [];
    }

    private getHideEmptyFields(userSettings: UserContextUserSettingModel[]): boolean {
        const dealDetailsSettings = this.getUserSettingValue(userSettings, 'app_fixedIncomeDealDetailsSettings');
        if (dealDetailsSettings && typeof dealDetailsSettings.hideEmptyFields === 'boolean') {
            return dealDetailsSettings.hideEmptyFields;
        }

        return false;
    }

    private getShowChanges(userSettings: UserContextUserSettingModel[]): boolean {
        const dealDetailsSettings = this.getUserSettingValue(userSettings, 'app_fixedIncomeDealDetailsSettings');
        if (dealDetailsSettings && typeof dealDetailsSettings.showChanges === 'boolean') {
            return dealDetailsSettings.showChanges;
        }

        return true;
    }

    private getOrderEntryFormat(userSettings: UserContextUserSettingModel[]): OrderEntryFormat {
        const orderEntryFormat = this.getUserSettingValue(userSettings, 'app_orderEntryFormat');

        return orderEntryFormat || OrderEntryFormat.Additive;
    }

    private getMaxLevelAmount(userSettings: UserContextUserSettingModel[]): string {
        const maxLevelAmount = this.getUserSettingValue(userSettings, 'app_maxLevelAmount');

        return maxLevelAmount || null;
    }

    private getOrderHedgeType(userSettings: UserContextUserSettingModel[]): OrderHedgeType {
        const orderHedgeType = this.getUserSettingValue(userSettings, 'app_hedgeType');

        return orderHedgeType || null;
    }


    private getPricedDealsWindowDays(userSettings: UserContextUserSettingModel[]): number {
        const dealDetailsSettings = this.getUserSettingValue(userSettings, 'app_dealMonitor1RequestSettings');
        if (dealDetailsSettings && typeof dealDetailsSettings.pricedDealsWindowDays === 'number') {
            return dealDetailsSettings.pricedDealsWindowDays;
        }

        return this.PRICED_DEALS_WINDOW_DAYS_DEFAULT;
    }

    private getShowChineseDeals(userSettings: UserContextUserSettingModel[]): boolean {
        const dealDetailsSettings = this.getUserSettingValue(userSettings, 'app_dealMonitor1RequestSettings');
        if (dealDetailsSettings && typeof dealDetailsSettings.showChineseDeals === 'boolean') {
            return dealDetailsSettings.showChineseDeals;
        }

        return true;
    }

    private getSingleInAppEmailNotifications(userSettings: UserContextUserSettingModel[]): boolean {
        const singleInAppEmailNotifications = <boolean>this.getUserSettingValue(userSettings, 'app_singleInAppEmailNotifications');

        return singleInAppEmailNotifications || false;
    }

    private getCompanies(companies: UserContextProfileCompany[]): Company[] {
        if (!companies) {
            return [];
        }

        return companies.map(company => ({
            containerId: company.ContainerId,
            id: company.CompanyId,
            name: company.Name,
            orionId: company.OrionId,
            parentCompanyId: company.ParentCompanyId
        }));
    }

    private getContainers(containers: UserContextProfileContainer[]): Container[] {
        if (!containers) {
            return [];
        }

        return containers.map(container => ({
            id: container.Id,
            isTestContainer: container.IsTestContainer,
            name: container.Name,
            status: container.Status,
            isAdmin: container.IsAdmin
        }));
    }

    private getUserSettingValue(userSettings: UserContextUserSettingModel[], settingName: string): any {
        return userSettings
            .filter((setting) => setting.SettingKey === settingName)
            .map((option) => {
                const parsedOption = JSON.parse(option.SettingValue);

                return parsedOption.type === 'object' ? JSON.parse(parsedOption.value) : parsedOption.value;
            })[0];
    }

    private getDefaultOperationalEntities(userSettings: UserContextUserSettingModel[]): DefaultOperationalEntity[] {
        const oeSetting = userSettings.find(us => us.SettingKey === 'app_defaultOperationalEntities');
        if (oeSetting === undefined) {
            return [];
        }

        try {
            const defaultOperationalEntities = <DefaultOperationalEntities>JSON.parse(oeSetting.SettingValue);
            return defaultOperationalEntities.entities || [];
        }
        catch (ex) {
            console.error('Error while parsing operational entities', ex);
            return [];
        }
    }

    private getOrderHedgeSettlement(userSettings: UserContextUserSettingModel[]): string {
        const orderHedgeSettlement = this.getUserSettingValue(userSettings, 'app_hedgeSettlement');

        return orderHedgeSettlement || null;
    }
}
